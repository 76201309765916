
let language = document.querySelector('html').getAttribute('lang');
language = language == 'de' ? '' : language; 

// options to adjust for current project
const options = {
    idPrefix: `/${language}#`,
    activeClass: 'active',
    areaClass: '.pimcore_area_content',
    detectionLinePercentageTop: 60
};

const areaNavEls = document.querySelectorAll('#nav li.nav-item'); // collect all nav-items
const areaEls = document.querySelectorAll(options.areaClass); // collect all area-elements

let observer = new IntersectionObserver((entries) => { // setup observer
    entries.forEach(entry => { // iterate over entries that the observer has received (further down inside of iterations)
        if (entry.isIntersecting) { // if an entry (area) intersects with our root:
            areaNavEls.forEach(areaNavEl => areaNavEl.classList.remove(options.activeClass)); // remove active class from any nav-item
            entry.target.navEl.classList.add(options.activeClass); // add active class to nav-item which matches with intersection area
        }
    });
}, {
    // root is viewport, but we make it a thin line that sits a defined percentage away from top by using negative rootMargin
    rootMargin: `-${options.detectionLinePercentageTop}% 0px -${100 - options.detectionLinePercentageTop}% 0px`
});

areaEls.forEach(areaEl => { // iterate over all the collected areas
    areaNavEls.forEach(areaNavEl => { // iterate over all the collected nav-items
        const navItemLinkEl = areaNavEl.querySelector('a[href^="'+options.idPrefix+'"]'); // get hash-navigation anchor inside of nav-item
        
        if (navItemLinkEl) { // if hash-navigation anchor exists inside of nav-item:
            const areaNavID = navItemLinkEl.getAttribute('href').replace(options.idPrefix, ''); // get hash-navigation anchor id value
            if (areaEl.querySelector('[data-anchor-area][id="'+areaNavID+'"]')) { // if iterated area has element inside with [data-anchor-area] and matching id:
                areaEl['navEl'] = areaNavEl; // tie matching navigation element to area-element
                observer.observe(areaEl); // pass prepared area-element over to observer
            }   
        }
    });
});
