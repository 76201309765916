const speakerELs = document.querySelectorAll('.speaker__person');

speakerELs.forEach((speakerEL) => {
    const pictureEL = speakerEL.querySelector('.picture');
    const detailsEL = speakerEL.querySelector('.details');
    const toggleDetails = () => {
        if (speakerEL.classList.contains("active")) {
            speakerEL.classList.remove("active");
        }
        else {
            speakerEL.classList.add("active");
        }
    }; 
    pictureEL.addEventListener("click", toggleDetails); 
    detailsEL.addEventListener("click", toggleDetails);  
});