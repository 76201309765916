const keyvisualEL = document.querySelector('#videoKV');
if(keyvisualEL) {
    const keyvisualVideoEL = keyvisualEL.querySelector('video');
    if (keyvisualVideoEL) {
        keyvisualEL.addEventListener("click", () => {
            if (keyvisualVideoEL.paused) {
                keyvisualVideoEL.play();
                keyvisualEL.classList.add("playing");
            }
            else {
                keyvisualVideoEL.pause();
                keyvisualEL.classList.remove("playing");
            }
        });
    }
}

