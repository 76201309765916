console.log('webpack running');

// icons
const req = require.context("./icons/", false, /\.svg$/);
req.keys().map(req);

// JS
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
import './js/menu.js';
import './js/kv-video.js';
import './js/speakers.js';
import './js/scrollDetect.js';

// SCSS
import './scss/main.scss';
