const menubuttonEL = document.querySelector('#hamburger');
const navEL = document.querySelector('#nav');

const openMenu = () => {
    menubuttonEL.classList.add("active");
    navEL.classList.add("active");
};
const closeMenu = () => {
    menubuttonEL.classList.remove("active");
    navEL.classList.remove("active");
};

const toggleMenu = () => {
    menubuttonEL.classList.contains("active") ? closeMenu() : openMenu();
};

menubuttonEL.addEventListener("click", () => toggleMenu());

const navLinkELs = navEL.querySelectorAll('a[href^="/#"]');
navLinkELs.forEach(navLinkEL => {
    navLinkEL.addEventListener("click", () => closeMenu());
});

 